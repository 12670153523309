<template>
    <div class="loginBG">
        <div class="title">{{wordInfo.DictationName}}</div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <div class="contentbox" v-if="!MultiBlock">
            <div class="wordBox">
                <div class="worditem" v-for="item in wordList" :key="item.WordID">
                    <div class="bosxs">
                        <div class="pinBox" v-for="item1 in item.itemPin" ><span v-if="isShow || wordInfo.DictationPrint == 1 || wordInfo.DictationPrint == 13 ">{{item1}}</span></div>
                    </div>
                    <div class="bosxs">
                        <div class="ziBox" v-for="item1 in item.typeface" ><span v-if="isShow || wordInfo.DictationPrint == 2 || wordInfo.DictationPrint == 23 ">{{item1}}</span></div>
                    </div>
                </div>
            </div>
            <div class="setBox">
                <div class="startBtn" @click="goVadio">准备默写</div>
                <div class="startBtn" style="margin-top:30px" @click="showWord"><span v-if="!isShow">显示答案</span> <span v-if="isShow">隐藏答案</span></div>
                <Button type="primary" class="goback" @click="goback"><Icon  size="20" style="margin-top:-4px" type="md-arrow-back" />退出</Button>
            </div>
        </div>
        <div class="contentbox" v-if="MultiBlock">
            <div class="wordBox1" >
                <div class="worditem"  v-for="item in wordList" :key="item.CourseID">
                    <div class="titles">{{item.CourseName}}</div>
                    <div class="coltents">
                         <div class="rightBoxs" v-for="item2 in item.Words" :key="item2.WordID">
                            <div class="bosxs">
                                <div class="pinBox" v-for="item1 in item2.itemPin" ><span v-if="isShow || item.mode ==1 ||  wordInfo.DictationPrint == 1 || wordInfo.DictationPrint == 13 ">{{item1}}</span></div>
                            </div>
                            <div class="bosxs">
                                <div class="ziBox" v-for="item1 in item2.typeface" ><span v-if="isShow || item.mode == 2 || wordInfo.DictationPrint == 2 || wordInfo.DictationPrint == 23 ">{{item1}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="setBox">
                <div class="startBtn" @click="goVadio">准备默写</div>
                <div class="startBtn" style="margin-top:30px" @click="showWord"><span v-if="!isShow">显示答案</span> <span v-if="isShow">隐藏答案</span></div>
                <Button type="primary" class="goback" @click="goback"><Icon  size="20" style="margin-top:-4px" type="md-arrow-back" />退出</Button>
            </div>
        </div>
    </div>

</template>
  
<script>
import axios from "axios";
import qs from "qs"
export default {
    data() {
      return {
        id:'',
        wordInfo:'',
        wordList:'',
        isShow:false,
        spinShow:true,
        showType:1,
        MultiBlock:false
      };
    },
    created() {
        this.id = this.$route.query.id
        this.queryWordList()
    },
    mounted() {
     
    },
    methods: {
     OptBtn(){
        this.showType = this.showType == 1 ? '2':'1'
     },
    showWord(){
        this.isShow=  !this.isShow
    },
    goback(){
        this.$router.push('topicList')
    },
    goVadio(){
        this.$router.push({name:'answerPage',query:{id:this.id,showType:this.showType}})
    },
    queryWordList(){
        const that = this
        axios({
          headers:{
            'Content-Type':'application/x-www-form-urlencoded',
            // 'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiJEMTk4RDg4My05NUFGLTQ4OTgtOEJCQi1CNTkxN0MyMDVCQUIiLCJleHAiOjE2NjM2ODE1NzJ9.W_JdbznC0vIvfcbMn6uMIU82z_pjcJ2xZkEfCoIhNcU'
          },
          method: 'POST',
          url: '/Home/ReadDictation',
          data:qs.stringify({
                id:window.localStorage.getItem('sessionId'),
                DictationID: that.id
            })  
          })
        .then((res) => {
            console.log(res)
            if(res.data.success){
                that.spinShow = false
                that.wordInfo = res.data.data
                let datalist = []
                if(res.data.data.MultiBlock){
                    let lisitems = res.data.data.MultiBlockWords
                    this.MultiBlock = true
                    lisitems.map(item=>{
                    let words = item.Words
                    var listData = []
                    for(var i = 0;i<words.length;i++){
                        var data = {
                        itemPin:[],
                        typeface:[],
                        itemPin1:words[i].WorkPinYin,
                        typeface1:words[i].WordName,
                        isErr:true,
                        WordID:words[i].WordID
            
                        }
                        data.itemPin = words[i].WorkPinYin.split(' ')
                        data.typeface = words[i].WordName.split('')
                        listData.push(data)
                        console.log(listData)
                    }
                    let data1 = {
                        CourseID:item.CourseID,
                        CourseName:item.CourseName,
                        Words:listData,
                        mode:item.mode
                    }
                    datalist.push(data1)
                    })
                }else if(!res.data.data.MultiBlock && res.data.data.SimpleWords){
                    let SimpleWords = res.data.data.SimpleWords
                    for(var i = 0;i<SimpleWords.length;i++){
                    let data = {
                        itemPin:[],
                        typeface:[]
                    }
                    data.itemPin = SimpleWords[i].WorkPinYin.split(' ')
                    data.typeface = SimpleWords[i].WordName.split('')
                    datalist.push(data)
                    }
                }else if(!res.data.data.MultiBlock && !res.data.data.SimpleWords){
                    let lisitems = res.data.data.MultiBlockWords
                    this.MultiBlock = true
                    lisitems.map(item=>{
                    let words = item.Words
                    var listData = []
                    for(var i = 0;i<words.length;i++){
                        var data = {
                        itemPin:[],
                        typeface:[],
                        itemPin1:words[i].WorkPinYin,
                        typeface1:words[i].WordName,
                        isErr:true,
                        WordID:words[i].WordID
            
                        }
                        data.itemPin = words[i].WorkPinYin.split(' ')
                        data.typeface = words[i].WordName.split('')
                        listData.push(data)
                        console.log(listData)
                    }
                    let data1 = {
                        CourseID:item.CourseID,
                        CourseName:item.CourseName,
                        Words:listData,
                        mode:item.mode
                    }
                    datalist.push(data1)
                    })
                }
                console.log(datalist)
                
                that.wordList = datalist

            }else{
                if(res.data.msg == '无效的sessionid'){
                    that.$router.push({name:'login'});
                }else{
                    that.$Message.error(res.data.msg);
                }
            }
            })
        .catch((err) => {
            that.$Message.error(err);
        });
      }
    },
  };
  </script>
  
  <style scoped>
    .loginBG{
        width: 100%;
        height: 100%;
        background: url('https://r.bjwxjs.com/PC_kpy/pc_topic_bg.png') 0 0 / 100% 100% no-repeat;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    .contentbox{
        padding:50px;
        box-sizing: border-box;
        display: flex;
        height: 100%;
        margin:0 auto;
        width: 100%;
       
    }
    .wordBox{
        width: 90%;
        height: 90%;
        overflow: auto;
        display: flex;
        flex-wrap:wrap;
        padding-bottom: 200px;
        padding-left: 60px;
        
    }
    .wordBox1{
        width: 90%;
        height: 90%;
        padding-left: 60px;
         overflow: auto;
    }
    
   
    
    .setBox{
        width: 10%;
        height: 100%;
    }
    .pinBox{
        font-size: 28px;
        font-family: "pinyin";
        color: #000000;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
        background: url('https://r.bjwxjs.com/PC_kpy/IMG_9180.png') center center / 100% 60% no-repeat;
    }
    .pinBox:nth-child(2){
        margin-left: -1px;
    }
    .pinBox:nth-child(3){
        margin-left: -1px;
    }
    .pinBox:nth-child(4){
        margin-left: -1px;
    }
    .ziBox{
        font-size: 60px;
        color: #000000;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
        background: url('https://r.bjwxjs.com/PC_kpy/pc_fangge_icon.png') 0 0 / 100% 100% no-repeat;
    }
    .ziBox:nth-child(2){
        margin-left: -1px;
    }
    .ziBox:nth-child(3){
        margin-left: -1px;
        
    }
    .ziBox:nth-child(4){
        margin-left: -1px;
    }
    .OptBtn{
        width: 120px;
        height: 40px;
        font-size: 20px;
        margin-top:20px;
        line-height: 22px;
    }
    .ivu-dropdown-menu{
        width: 180px;
        font-size: 24px;
    }
    .ivu-dropdown-item{
        height: 50px;
        font-size: 24px !important;
        text-align: center;
    }
    .startBtn{
        width: 120px;
        height: 50px;
        margin-top: 300px;
        background: url('https://r.bjwxjs.com/PC_kpy/pc_button_BG@2x.png') 0 0 / 100% 100% no-repeat;
        font-size: 22px;
        font-weight: normal;
        color: #207DE5;
        text-align: center;
        line-height: 50px;
        cursor:pointer
    }
    .goback{
        width: 120px;
        height: 40px;
        margin-top: 30px;
        font-size: 22px;
        line-height: 24px;
    }
    .title{
        font-size: 40px;
        font-weight: 500;
        color: #303133;
        width: 100%;
        margin: 50px auto 0;
        text-align: center;
    }
    .worditem{
       margin-right: 16px;
    }
    .worditem .bosxs{
        display: flex;
        flex-wrap: wrap;
    }
    .coltents{
        display: flex;
        flex-wrap: wrap;
    }
    .rightBoxs{
        margin-right:16px;
    }
   
    
    .titles{
        width: 100%;
        font-size: 26px;
        margin-top: 20px;
    }
     ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    scrollbar-width:none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  </style>
  